import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Option } from 'types/options';
import { Routes } from 'types/routes';

import City from 'models/City';
import JobTime from 'models/JobTime';

import CloseIcon from 'theme/icons/x.svg';

import Button, { ButtonIconPositions, ButtonIconTypes, ButtonLayouts, ButtonStyles, ButtonTypes, ButtonVariants } from 'components/layout/Button';
import Form from 'components/layout/forms/Form';
import Input from 'components/layout/forms/Input';
import Select, { InputStyles } from 'components/layout/forms/Select';
import CitiesSelect from 'components/modules/public/layout/cities/Select';
import { ISearchParam, SearchParamCity, SearchParamTime, SearchParamValue, useSearchParams } from 'hooks/useSearchParams';

import { Props } from './index';
import StyledComponent from './styles';

export const rangesRadius = [{
    label: '+ 10 km',
    value: 10,
}, {
    label: '+ 20 km',
    value: 20,
}, {
    label: '+ 30 km',
    value: 30,
}, {
    label: '+ 50 km',
    value: 50,
}, {
    label: '+ 75 km',
    value: 75,
}, {
    label: '+ 100 km',
    value: 100,
}];

const PublicLayoutFormsSearchFormBlocksMain: FunctionComponent<Props> = ({
    title,
    input,
    buttonProps,
    jobTimes,
    searchParams: globalSearchParams,
    onSearch,
    children,
    instruction,
    instructionOnSearchPage,
}) => {
    const [instructionVisible, setInstructionVisible]: [boolean, Function] = useState(false);
    const router = useRouter();

    const { getSearchParams, getSearchParamsQueryValues, getSearchParamValue, setSearchParamValue } = useSearchParams({
        city: new SearchParamCity('jobCompanyCitySlug'),
        radius: new SearchParamValue('rangeRadius'),
        time: new SearchParamTime('jobTimeSlug'),
        name: new SearchParamValue('name'),
    });

    useEffect(() => {
        setSearchParamValue('city', globalSearchParams.city.getValue() );
        setSearchParamValue('radius', globalSearchParams.radius.getValue() );
        setSearchParamValue('time', globalSearchParams.time.getValue() );
        setSearchParamValue('name', globalSearchParams.name.getValue() );
    }, [globalSearchParams]);

    const searchParams = getSearchParams();

    const handleSearch = () => {
        onSearch( searchParams );
    };

    return (
        <StyledComponent
            className={classnames(
                'public-layout-forms-search-form-blocks-main',
            )}
        >
            <div
                className="title-row"
                id="search"
            >
                {instructionVisible && (
                    <div className={classnames('instructionTip', {
                        'onSearchPage': Boolean(instructionOnSearchPage === 'true'),
                    })}
                    >
                        <Button
                            className='close-button'
                            type={ButtonTypes.Button}
                            variant={ButtonVariants.Outline}
                            style={ButtonStyles.None}
                            onClick={() => setInstructionVisible(!instructionVisible)}
                            icon={{
                                type: ButtonIconTypes.Image,
                                position: ButtonIconPositions.Before,
                                value: CloseIcon,
                            }}
                        >
                        </Button>
                        <div
                            className="instructionTip-text"
                            dangerouslySetInnerHTML={{ __html: instruction }}
                        />
                    </div>
                )}
                <div className="buttons">

                    <div
                        className="form-title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />

                    {buttonProps[1] && (
                        <div className='title-button-wrapper'>
                            <Button
                                className="button"
                                style={ButtonStyles.Secondary}
                                variant={ButtonVariants.Outline}
                                layout={ButtonLayouts.Wide}
                                ariaLabel={buttonProps[1].children}
                                icon={{
                                    type: ButtonIconTypes.Image,
                                    position: ButtonIconPositions.Before,
                                    value: '/images/jobs/help.svg',
                                    hover: '/images/jobs/help-green.svg',
                                    width: 24,
                                    height: 24,
                                }}
                                {...buttonProps[1]}
                                onClick={() => setInstructionVisible(!instructionVisible)}
                            />
                        </div>
                    )}
                </div>

            </div>
            <Form >
                {({ }) => {
                    return (
                        <div className="form-input-row">
                            <div className="row-inputs">
                                <CitiesSelect
                                    selectProps={{
                                        name: 'companyCitySearch',
                                        onChange: (selectedOption: Option<City>) => {
                                            if (selectedOption === null)
                                                setSearchParamValue('city', null, true);
                                            else
                                                setSearchParamValue('city', selectedOption.value);
                                        },
                                        value: searchParams.city?.getValue() ? {
                                            label: searchParams.city?.getValue()?.name,
                                            value: searchParams.city?.getValue(),
                                        } : null,
                                        style: InputStyles.Secondary,
                                    }}
                                />

                                <Select
                                    name="rangeRadius"
                                    style={InputStyles.Secondary}
                                    placeholder={input?.distance?.placeholder}
                                    onChange={ (selectedOption: Option<any>) => {
                                        if (selectedOption === null)
                                            setSearchParamValue('radius', null, true);
                                        else
                                            setSearchParamValue('radius', selectedOption.value);
                                    }}
                                    options={Array.isArray(rangesRadius) && rangesRadius.map(range => range) || []}
                                    value={ rangesRadius.find( rangesRadius => rangesRadius.value == searchParams.radius.getValue() ) }
                                />
                                <Select
                                    name="jobTimeSlug"
                                    style={InputStyles.Secondary}
                                    placeholder={input?.jobTime?.placeholder}
                                    label={input?.jobTime?.label}
                                    onChange={ (selectedOption: Option<JobTime>) => {
                                        if (selectedOption === null)
                                            setSearchParamValue('time', null, true);
                                        else
                                            setSearchParamValue('time', selectedOption.value);
                                    }}
                                    value={ searchParams.time.getValue() }
                                    options={Array.isArray(jobTimes) && jobTimes.map(jobTime => ({
                                        label: jobTime?.name,
                                        value: jobTime?.slug,
                                    })) || []}
                                />
                                <Input
                                    name="name"
                                    type="text"
                                    style={InputStyles.Secondary}
                                    label={input?.search?.label}
                                    placeholder={input?.search?.placeholder}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchParamValue('name', event.target.value)}
                                    value={searchParams.name.getValue()}
                                />
                            </div>
                            <Button
                                className="submit"
                                type={ButtonTypes.Submit}
                                layout={ButtonLayouts.Wide}
                                onClick={handleSearch}
                                icon={{
                                    type: ButtonIconTypes.Image,
                                    position: ButtonIconPositions.Before,
                                    value: '/images/home/hero/search.svg',
                                    width: 18,
                                    height: 18,
                                }}
                                {...buttonProps[0]}
                            />
                        </div>
                    );
                }}
            </Form>
            {buttonProps[2] && (
                <div className="button-form-footer">
                    <Button
                        className="button"
                        style={ButtonStyles.Secondary}
                        variant={ButtonVariants.Primary}
                        onClick={() => router.push(Routes.PublicJobs)}
                        {...buttonProps[2]}
                    />
                </div>
            )}
            {children && (
                <div className="children">{children}</div>
            )}
        </StyledComponent>
    );
};

export default PublicLayoutFormsSearchFormBlocksMain;